import { useEffect, useState } from "react";
import { fetchKnowledgeBaseItems } from "../../services/cms";
import { buildTree, decodeHtml } from "../../services/utils";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import KnowledgeBaseContent from "./components/KnowledgeBaseContent";

const KnowledgeBase = () => {

    const { slug } = useParams();
    const [categories, setCategories] = useState([]);
    const [categoriesSlug, setCategoriesSlug] = useState({});
    const [articlesSlug, setArticlesSlug] = useState({});
    const [categoriesOpened, setCategoriesOpened] = useState({});
    const [toggle, setToggle] = useState(false);
    const [data, setData] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    // Access the extra data from the state property
    const isCategory = (location.state && location.state.type && location.state.type == 'category') || !location.state ? true : false;

    useEffect(() => {

        if(categories.length != 0){

            let defaultSlug = slug ? slug : 'getting-started';

            let dataContent = isCategory? categoriesSlug[defaultSlug] : articlesSlug[defaultSlug];
            
            setData(dataContent);

        } else {

            const getKnowledgeBaseData = async () => {
    
                let kbItems = await fetchKnowledgeBaseItems();
                
                if(Array.isArray(kbItems) && kbItems.length > 0){
                    let categoriesIndexMap = {};
                    let categoriesFinal = [];
                    let categoriesSlugAux = {};
                    let articlesSlugAux = {};
    
                    kbItems.forEach((item, index) => {
                        
                        if(
                            item['_embedded'] &&
                            item['_embedded']['wp:term'] &&
                            item['_embedded']['wp:term'][0] &&
                            item['_embedded']['wp:term'][0][0]
                        ){
    
                            let term = item['_embedded']['wp:term'][0][0];
    
                            let article = {
                                isCategory: false,
                                title:      item.title,
                                slug:       item.slug,
                                id:         item.id,
                                date:       item.date,
                                content:    item.content
                            };
    
                            item.isCategory = false;
                            articlesSlugAux[item.slug] = item;
                            
                            if((categoriesIndexMap[term['id']] || categoriesIndexMap[term['id']] === 0) && categoriesFinal[categoriesIndexMap[term['id']]]){
                                categoriesFinal[categoriesIndexMap[term['id']]].articles.push(article);
                                categoriesSlugAux[term.slug].articles.push(article);
                            }else{
                                categoriesIndexMap[term['id']] = categoriesFinal.length;
    
                                let parent = 0;
    
                                if(term['_links'] && term['_links']['up'] && term['_links']['up'].length > 0){
                                    let parentLink = term['_links']['up'][0].href;
                                    parent = parseInt(parentLink.substring(parentLink.lastIndexOf('/') + 1));
                                }
    
                                categoriesFinal.push({
                                    isCategory: true,
                                    id: term.id,
                                    parent: parent,
                                    name: term.name,
                                    slug:   term.slug,
                                    articles: [
                                        article
                                    ]
                                });
    
                                categoriesSlugAux[term.slug] = {
                                    isCategory: true,
                                    id: term.id,
                                    parent: parent,
                                    name: term.name,
                                    articles: [
                                        article
                                    ]
                                };
    
                            }
                        }
                    });
                    
                    setCategories(buildTree(categoriesFinal));
                    setArticlesSlug(articlesSlugAux);
                    setCategoriesSlug(categoriesSlugAux);
    
                    let defaultSlug = slug ? slug : 'getting-started';
    
                    let dataContent = isCategory? categoriesSlugAux[defaultSlug] : articlesSlugAux[defaultSlug];
                    
                    setData(dataContent);

                    if(isCategory){
                        let tempCategoriesOpened = categoriesOpened;
                        if(tempCategoriesOpened[defaultSlug]){
                            delete tempCategoriesOpened[defaultSlug];
                        }else{
                            tempCategoriesOpened[defaultSlug] = true;
                        }
                        setCategoriesOpened(tempCategoriesOpened);
                    }
                }
            };
    
            getKnowledgeBaseData();
        }

        setTimeout(() => window.scrollTo(0, 0), 500);
    }, [slug]);

    const returnCategoriesList = (list, parent = 0) => {
    
        let defaultSlug = slug ? slug : 'getting-started';

        return list.length > 0 && list.map((data, key) => {
            
            return <li className={`knowledgebase-category-type`} key={`${parent}${key}`}>
                <a 
                    className={categoriesOpened[data.slug] ? 'kb-item-opened' : ''}
                    onClick={() => {
                        let tempCategoriesOpened = categoriesOpened;
                        if(tempCategoriesOpened[data.slug]){
                            delete tempCategoriesOpened[data.slug];
                        }else{
                            tempCategoriesOpened[data.slug] = true;
                        }
                        setCategoriesOpened(tempCategoriesOpened);
                        navigate(`/knowledgebase/${data.slug}`, { state: { type: 'category'} });
                    }} 
                    data-id={data.id} 
                    dangerouslySetInnerHTML={{__html: data.name}}
                />
                {data.articles && data.articles.length > 0 && <ul className="knowledgebase-articles-list">
                    { data.articles.map((article, i) => {

                        if(defaultSlug == article.slug && !categoriesOpened[data.slug]){
                            let tempCategoriesOpened = categoriesOpened;
                            if(tempCategoriesOpened[data.slug]){
                                delete tempCategoriesOpened[data.slug];
                            }else{
                                tempCategoriesOpened[data.slug] = true;
                            }
                            setCategoriesOpened(tempCategoriesOpened);
                        }

                        return (
                            <li key={`${article.id}article`} className={`knowledgebase-article-type`}>
                                <a 
                                    className={defaultSlug == article.slug ? 'kb-item-selected' : ''}
                                    onClick={() => navigate(`/knowledgebase/${article.slug}`, { state: { type: 'article'} })} 
                                    dangerouslySetInnerHTML={{__html: article.title.rendered}} 
                                />
                            </li>
                        )
                    }) }
                </ul>}
                {data.children && data.children.length > 0 ? <ul className="knowledgebase-submenu">
                    {returnCategoriesList(data.children, data.id)}
                </ul> : <></>}
            </li>;
        });
    }

    const showTableOfContent = () => {

        let defaultSlug = slug ? slug : 'getting-started';

        let dataContent = isCategory? '' : articlesSlug[defaultSlug] ? decodeHtml(articlesSlug[defaultSlug].content.rendered) : '';

        let toc = "";
        let level = 0;
        let maxLevel = 5;
        if(document.getElementById("knowledgebase-contents")){
            document.getElementById("knowledgebase-contents").innerHTML = document.getElementById("knowledgebase-contents").innerHTML.replace(
                /<h([\d])>([^<]+)<\/h([\d])>/gi,
                function (str, openLevel, titleText, closeLevel) {
                    if (openLevel != closeLevel) {
                        return str + ' - ' + openLevel;
                    }
    
                    if (openLevel > level) {
                        toc += (new Array(openLevel - level + 1)).join("<ol>");
                    } else if (openLevel < level) {
                        toc += (new Array(level - openLevel + 1)).join("</ol>");
                    }
    
                    level = parseInt(openLevel);
    
                    var anchor = titleText.replace(/ /g, "_");
                    toc += "<li><a href=\"#" + anchor + "\">" + titleText
                        + "</a></li>";
    
                    return "<h" + openLevel + "><a name=\"" + anchor + "\">"
                        + titleText + "</a></h" + closeLevel + ">";
                }
            );
    
            if (level) {
                toc += (new Array(level + 1)).join("</ol>");
            }
        }

        return toc;
    }

    return <>
        <div onClick={() => {
            setToggle(prevState => {
                return !prevState;
              })
        }} className={`knowledgebase-toggle ${toggle ? 'close' : 'open'}`}>
            <div className="l fl"></div>
            <div className="l cl"></div>
            <div className="l sl"></div>
        </div>
        <div className="knowledgebase-page">
            <div className={`knowledgebase-column knowledgebase-index  ${!toggle ? 'close' : 'open'}`}>
                <div className="knowledgebase-sidebar">
                    <ul className="knowledgebase-menu">
                        {categories.length > 0 ? returnCategoriesList(categories, 0) : <></>}
                    </ul>
                </div>
            </div>
            <div id="knowledgebase-contents" className="knowledgebase-column knowledgebase-content">
                 
                {categories.length > 0 ? <KnowledgeBaseContent 
                    {...data}
                    categories={categoriesSlug}
                /> : <></>}

            </div>
        </div>
    </>;
}

export default KnowledgeBase;