import "./pricinglanding.css"
import TinyTitle from "../../../components/tinytitle/TinyTitle";

const PricingLanding = () => {
    return (
        <section className="price_landing">
            <div className="content_sec">
                <div className="bigrid">
                    <div className="text_side">
                        <TinyTitle title="plans" textcolor="#fff" />
                        <h1>Our Plans</h1>
                        <p>Find the right fit for your unique needs with our SharePass plans. Here, you can find a breakdown of each offering, ensuring you and your team choose the solution best aligned with your data security and privacy objectives.</p>
                    </div>
                </div>               
            </div>
        </section>
    )
}

export default PricingLanding;