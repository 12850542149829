import acs from '../../../assets/images/acs-logo.jpeg';
import natan from '../../../assets/images/natan.png';
import mysecurity from '../../../assets/images/mysecurity-marketplace.png';

const ReferralPartners = (props) => {
    return(
        <section className="distributors_info">
        <div className="content_sec">
            <h2>{props.title}</h2>
            <p>{props.para}</p>
            
            <div className="partners-distributor-container">
                    <div className="partners-distributor-item">
                        <div className="partners-distributor-item-content">
                            <img className="partners-distributor-centered-image" src={acs} />
                            <a className="partners-distributor-button" href="https://www.acs.org.au/" target="_blank">
                                Learn more
                            </a>
                        </div>
                    </div>
                    <div className="partners-distributor-item">
                        <div className="partners-distributor-item-content">
                            <img className="partners-distributor-centered-image" src={natan} />
                            <a className="partners-distributor-button" href="https://natan.com.au" target="_blank">
                                Learn more
                            </a>
                        </div>
                    </div>
                    <div className="partners-distributor-item">
                        <div className="partners-distributor-item-content">
                            <img className="partners-distributor-centered-image" src={mysecurity} />
                            <a className="partners-distributor-button" href="https://mysecuritymarketplace.com/v2-solutions/sharepass/" target="_blank">
                                Learn more
                            </a>
                        </div>
                    </div>
                </div>
        </div>
    </section>
    )
}

export default ReferralPartners;