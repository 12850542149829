import "./scrollstep.css";
import {useEffect, useState, useRef} from "react";
import React, {Component} from "react";
import Slider from "react-slick";
import LearnMore from "../../../components/learnmore/LearnMore";

// mp4s
import seamlessIntegration from "../../../assets/animations/Seamless-integration.mp4";


const ScrollStep = (props) => {
	const settings = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		speed: 500,
		cssEase: "linear",
	};
	const [activeNum, setActiveNum] = useState(1);

	const sectionRefs = [useRef(null), useRef(null), useRef(null)];

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "0px",
			threshold: 0.5,
		};
		const handleIntersection = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					if (entry.target.id === "one") {
						setActiveNum(1);
					} else if (entry.target.id === "two") {
						setActiveNum(2);
					} else if (entry.target.id === "three") {
						setActiveNum(3);
					}
				}
			});
		};
		const observer = new IntersectionObserver(handleIntersection, options);
		sectionRefs.forEach((ref) => {
			if (ref.current) {
				observer.observe(ref.current);
			}
		});
		return () => {
			sectionRefs.forEach((ref) => {
				if (ref.current) {
					observer.unobserve(ref.current);
				}
			});
		};
	}, []);

	return (
		<div className="scroll_step">
			<div className="content_sec">
				{/*For width >= 900  */}
				<div className="step_grid">
					<div className="stepimg_wrap">
						<div className="sticky_imagewrap">
							<video
								id="videoScrollable"
								src={seamlessIntegration}
								autoPlay
								loop
								muted
								playsInline
							/>
						</div>
					</div>
					<div className="steptext_wrap">
						<div
							className="block"
							style={{opacity: activeNum === 1 ? 1 : 0.4, transition: "all 0.5s linear"}}
							id="one"
							ref={sectionRefs[0]}
						>
							<h2>Seamless Integration for Secure Data Sharing</h2>
							<p>Use SharePass with your existing systems and use the convenient integration options to enhance your data-sharing capabilities.</p>
						</div>
						<div
							className="block"
							style={{opacity: activeNum === 2 ? 1 : 0.4, transition: "all 0.5s linear"}}
							id="two"
							ref={sectionRefs[1]}
						>
							<h2>Power Your Apps with SharePass RESTful API</h2>
							<p>Accelerate app development with SharePass RESTful API, the secure and easy way to integrate data-sharing capabilities into your existing applications and workflows. Whether building a new app or enhancing one, our API provides the flexibility and convenience you need.</p>
							<div style={{marginTop: '20px'}} className="learn_wrap">
								<LearnMore
									external={true}
									target="blank"
									title="Learn More"
									top='0px'
									boxcolor="green"
									textcolor="#000"
									path="https://developers.sharepass.com"               
								/>
							</div>
						</div>
						<div
							className="block"
							style={{opacity: activeNum === 3 ? 1 : 0.4, transition: "all 0.5s linear"}}
							id="three"
							ref={sectionRefs[2]}
						>
							<h2>Get Alerts</h2>
							<p>Use SharePass webhooks to ensure secure and reliable data sharing, with the ability to verify the authenticity of the webhook received.</p>
						</div>
					</div>
					<div className="mile_stone">
						<div className="point_wrap">
							<div className={`point ${activeNum === 1 ? "active" : ""}`}></div>
							<div className={`point ${activeNum === 2 ? "active" : ""}`}></div>
							<div className={`point ${activeNum === 3 ? "active" : ""}`}></div>
						</div>
					</div>
				</div>
				{/* {For width < 900px */}
				<div className="tab_slider">
					<Slider {...settings}>
						<div className="slide">
							<h2>Seamless Integration for Secure Data Sharing</h2>
							<p>Use SharePass with your existing systems and use the convenient integration options to enhance your data-sharing capabilities.</p>
						</div>

						<div className="slide">
							<h2>Power Your Apps with SharePass RESTful API</h2>
							<p>Accelerate app development with SharePass RESTful API, the secure and easy way to integrate data-sharing capabilities into your existing applications and workflows. Whether building a new app or enhancing one, our API provides the flexibility and convenience you need.</p>
							<div style={{marginTop: '20px', textAlign: 'center'}} className="learn_wrap">
								<LearnMore
									external={true}
									target="blank"
									title="Learn More"
									top='0px'
									boxcolor="green"
									textcolor="#000"
									path="https://developers.sharepass.com"               
								/>
							</div>
						</div>

						<div className="slide">
							<h2>Get Alerts</h2>
							<p>Use SharePass webhooks to ensure secure and reliable data sharing, with the ability to verify the authenticity of the webhook received.</p>
						</div>
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default ScrollStep;
