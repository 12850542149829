import "./resourcesdropdown.css";
import SectionTitle from "./sectiontitle/SectionTitle";
import { Fragment, useEffect, useState, useRef } from 'react';
import articlibg from "../../../assets/images/article-bg-dots.avif";
import blockbg from "../../../assets/images/blog-media-dots.avif";
import eventbg from "../../../assets/images/event-layer.avif";
import BlockCard from "./blockcard/BlockCard";
import axios from "axios";
import pdfFile from "../../../assets/GISEC24-Sharepass.pdf";
import resourcesImg from '../../../assets/images/resources-landing.png';
import videoGuide from '../../../assets/images/video-guide.png';
import {apiBaseUrl} from "../../../assets/js/blogConfig";
import { useNavigate } from "react-router-dom";

const ResourcesDropdown = () => {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState(1);
    const [blogData, setBlogData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [mediaData, setMediaData] = useState([]);
    
    const [blogPage, setBlogPage] = useState(1);
    const [blogHeaders, setBlogHeaders] = useState({});
    const [blogLoading, setBlogLoading] = useState(false);

    const [eventPage, setEventPage] = useState(1);
    const [eventHeaders, setEventHeaders] = useState({});
    const [eventLoading, setEventLoading] = useState(false);

    const [mediaPage, setMediaPage] = useState(1);
    const [mediaHeaders, setMediaHeaders] = useState({});
    const [mediaLoading, setMediaLoading] = useState(false);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const getblogs = async (page = 1) => {
        setBlogLoading(true);
        await axios.get(`${apiBaseUrl}posts?_embed=true&per_page=4&page=${page}`)
            .then((response) => {
                setBlogHeaders(response.headers);
                setBlogData([].concat(blogData, response.data));

            })
            .catch((error) => {
                console.log(error);
            });
        setBlogLoading(false);
    }

    const getEvents = async (page = 1) => {
        setEventLoading(true);
        await axios.get(`${apiBaseUrl}event?per_page=3&page=${page}`)
            .then((response) => {
                setEventHeaders(response.headers);
                setEventData([].concat(eventData, response.data));
            })
            .catch((error) => {
                console.log(error);
            });
        setEventLoading(false);

    }

    const getMediaPosts = async (page = 1) => {
        setMediaLoading(true);
        await axios.get(`${apiBaseUrl}mediapost?_embed=true&per_page=4&page=${page}`)
            .then((response) => {
                setMediaHeaders(response.headers);
                setMediaData([].concat(mediaData, response.data));

            })
            .catch((error) => {
                console.log(error);
            });
        setMediaLoading(false);
    }

    useEffect(() => {
        getblogs();
        getEvents();
        getMediaPosts();

    }, []);

    const handleChangeSection = (e) => {
      const { value } = e.target;
  
      //setActiveSection(value);
    };



    const handleDropdownClick = (e) => {

        const dropdown = document.getElementById('dropbox')
        const selectBox = dropdown.querySelector('select.select-box')
        const selection = document.getElementById('selected_title')
        
        const dropList = dropdown.querySelector('ul.list-items')
        if(dropList.classList.contains('open')){
            dropList.classList.remove('open')
        }else{
            dropList.classList.add('open')
        }
        if(e.target.localName=='li' && e.target.dataset.value){
            selectBox.value = e.target.dataset.value
            selection.innerHTML = e.target.innerHTML
            dynamicListItemChanges(dropList, e.target)
            setActiveSection(selectBox.value);
        }
    }

    const dynamicListItemChanges = (listItems, selectedItem) => {
        for(let i = 0;i<listItems.children.length;i++){
            let li = listItems.children.item(i)
            if(li) li.classList.remove('active')
        }
        selectedItem.classList.add('active')
    }

    return (
        <section className="resource_stepper">
            <div className="content_sec">
                <div className="dropdown-select" onClick={handleDropdownClick} id="dropbox">
                    <select 
                        className="select-box"
                        value={activeSection}
                        onChange={handleChangeSection}
                    >
                        <option></option>
                        <option value="1">Blogs</option>
                        <option value="2">Events</option>
                        <option value="3">Media</option>
                        <option value="4">Knowledge Base</option>
                        <option value="5">Video Guide</option>
                    </select>
                    <span className="selection" id="selected_title">Blogs</span>
                    <span className="right-icon">
                        <i className="arrow-caret down"></i>
                    </span>
                    <ul className="list-items">
                        <li data-value="1">Blogs</li>
                        <li data-value="2">Events</li>
                        <li data-value="3">Media</li>
                        <li data-value="4">Knowledge Base</li>
                        <li data-value="5">Video Guide</li>
                    </ul>  
                </div>

                <div className="step_grid">

                    <div className="sec_content">
                        <div className="sec sec_article" style={{display: activeSection == 1? '' : 'none'}} >
                            <SectionTitle title="Blog Articles" img={articlibg} />
                            {blogData.length > 0 && <div className="cards_grid">
                                {blogData.map((data) => {
                                    return (<Fragment key={data.slug}>
                                        <BlockCard title={data.title? data.title.rendered : ""} img={data.fimg_url} type={'Blog'} id={data.slug} date={formatDate(data.date)} />
                                    </Fragment>)
                                })}
                            </div>}

                            {blogHeaders && blogPage < blogHeaders["x-wp-totalpages"] && <div className="btn_wrap">
                                <button onClick={async () => {
                                    const newPage = blogPage + 1;
                                    await getblogs(newPage);
                                    setBlogPage(newPage);
                                }} type="button" disabled={blogLoading}>load more</button>
                            </div>}

                            {blogData.length === 0 && <p className="notice">Currently no upcoming blogs, please view our past blogs.</p>}
                        </div>

                        <div className="sec sec_event" style={{display: activeSection == 2? '' : 'none'}} >
                            <SectionTitle title="Upcoming Events" img={blockbg} />
                            <div className="events-container events-container-resources">
                                {eventData.length > 0 && eventData.map((data) => {
                                            return <Fragment key={data.slug}>
                                                <div className="event_card">
                                                    <div className="event_inner_card">
                                                        <div className="image_wrap">
                                                            <img src={data.fimg_url}></img>
                                                        </div>
                                                        <div className="text_wrap">
                                                            <small>{data.event_reference_date}</small>
                                                            <h3 dangerouslySetInnerHTML={{__html: data.title? data.title.rendered : ""}} />
                                                            <p dangerouslySetInnerHTML={{__html: data.excerpt ? data.excerpt.rendered : ""}} />
                                                        </div>
                                                    </div>
                                                    <div className="event_link">
                                                        <a onClick={() => {
                                                            navigate(`/event/${data.slug}`);
                                                        }} download>Know&nbsp;More</a>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }
                                    )
                                }
                            </div>
                            {eventData.length === 0 && <p className="notice">Currently no upcoming events, please view our past events.</p>}
                            {eventHeaders && eventPage < eventHeaders["x-wp-totalpages"] && <div className="btn_wrap">
                                <button onClick={async () => {
                                    const newPage = eventPage + 1;
                                    await getEvents(newPage);
                                    setEventPage(newPage);
                                }} type="button" disabled={eventLoading}>load more</button>
                            </div>}
                        </div>

                        <div className="sec sec_media" style={{display: activeSection == 3? '' : 'none'}} >
                            <SectionTitle title="Media Center" img={eventbg} />
                            {mediaData.length > 0 && <div className="cards_grid">
                                {mediaData.map((data) => {
                                    return (<Fragment key={data.slug}>
                                        <BlockCard title={data.title? data.title.rendered : ""} img={data.fimg_url} postType="media" subcategory="Media" id={data.slug} date={formatDate(data.date)} />
                                    </Fragment>)
                                })}
                            </div>}
                            {mediaData.length === 0 && <p className="notice">No Media available.</p>}
                            {mediaHeaders && mediaPage < mediaHeaders["x-wp-totalpages"] && <div className="btn_wrap">
                                <button onClick={async () => {
                                    const newPage = mediaPage + 1;
                                    await getMediaPosts(newPage);
                                    setMediaPage(newPage);
                                }} type="button" disabled={mediaLoading} >load more</button>
                            </div>}
                        </div> 
                        
                        <div className="sec sec_media" style={{display: activeSection == 4? '' : 'none'}} >
                            <SectionTitle title="Knowledge Base" img={eventbg} />
                            <section className='resources_landing'>
                                <div className='content_sec'>
                                    <div className='bigrid'>
                                        <div className='text_wrap'>
                                            <p>Read our documentation to take advantage of all features that SharePass has to offer and learn the main differences with the SharePass One (our Enterprise product).</p>

                                            <div className="btn_wrap">
                                                <button onClick={() => {navigate('/knowledgebase');}} type="button" className='outlined-purple'>Learn More</button>
                                            </div>

                                        </div>

                                        <div className='img_wrap'>
                                            <img src={resourcesImg} />
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                        
                        <div className="sec sec_media" style={{display: activeSection == 5? '' : 'none'}} >
                            <SectionTitle title="Video Guide" img={eventbg} />
                            <section className='resources_landing'>
                                <div className='content_sec'>
                                    <div className='bigrid'>
                                        <div className='text_wrap'>
                                            <p>Explore our guided video tutorials on using our powerful app. From getting started to advanced features, our experts will walk you through step-by-step instructions and practical demos. Maximize your app's potential and achieve your goals effortlessly!</p>

                                            <div className="btn_wrap">
                                                <button onClick={() => {navigate('/videolibrary');}} type="button" className='outlined-purple'>EXPLORE VIDEO LIBRARY</button>
                                            </div>

                                        </div>

                                        <div className='img_wrap'>
                                            <img src={videoGuide} />
                                        </div>

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ResourcesDropdown;