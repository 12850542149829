import {Fragment, useEffect, useState} from "react";                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
import {InView} from "react-intersection-observer";
import TinyTitle from "../../components/tinytitle/TinyTitle";
import "./enterprise.css";
import UseCases from "./usecases/UseCases";
import Helmet from "react-helmet";
import {defaultMetaDescription, defaultOgImg} from "../../assets/js/blogConfig";
import { useNavigate } from "react-router-dom";

// MP4s
import enterpriseVideo from "../../assets/animations/Enterprise.mp4";
import enterpriseVideoVer from "../../assets/animations/Enterprise-ver.mp4";
import EnterpriseFeature from "./EnterpriseFeature";

import { Carousel } from 'react-responsive-carousel';

const Enterprise = () => {
    const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [featuresVisible, setFeaturesVisible] = useState([false, false, false, false, false, false, false, false, false, false]);

    const contactSales = () =>{
        navigate(`/contactsales`);
    };

	const featuredItems = [
		{
			title: "File Exchange with SharePass ONE",
			description: "Experience enhanced file-sharing with SharePass ONE, designed for secure and streamlined organisational transfers. Each link operates as a distinct secure vault, all managed from a centralized dashboard. This allows for a comprehensive view of all your shared items, an insight into activity logs, and the ability to modify restrictions even post-sharing.",
			img: require('../../assets/images/enterprise/512/file-sharing.png')
		},
		{
			title: "Boost Data Protection with Security Keys",
			description: "Amplify your data protection by integrating security keys adding an advanced layer of access verification. These keys authenticate user access, ensuring only those with the key gain entry to sensitive data. Utilize NFC (near-field communication) or Biometric (fingerprint) methods to seamlessly unlock secrets, whether on mobile devices or PCs, striking a balance between user convenience and top-tier security.",
			img: require('../../assets/images/enterprise/512/security-keys.png')
		},
		{
			title: "Enrolling Security Keys (Both Locally and Remotely)",
			description: "Facilitate effortless enrolment of security keys, catering even to users without immediate access to their physical keys. This functionality upholds business operations, enhances authentication strength, and expedites the enrolment and onboarding procedure.",
			img: require('../../assets/images/enterprise/512/remote-local-security-key-enrollment.png')
		},
		{
			title: "White Labelling",
			description: "Infuse the SharePass ONE platform with your organization's unique branding. Benefit from a tailored domain, logo, and theme colors, elevating the user experience and solidifying your corporate presence.",
			img: require('../../assets/images/enterprise/512/white-labeling.png')
		},
		{
			title: "Enterprise Single Sign-On",
			description: "Seamlessly integrate SharePass ONE with Office 365, Google Workspace, Apple, and more using SAML-based Enterprise SSO. Streamline user access without compromising security.",
			img: require('../../assets/images/enterprise/512/enterprise-sso.png')
		},
		{
			title: "Centralized Administrative Dashboard",
			description: "Equip administrators with a dynamic dashboard for streamlined user and resource management. Establish uniform sharing protocols with global templates, labels, and security keys across the organisation. Implement and regulate controls aligned with your organization's security standards.",
			img: require('../../assets/images/enterprise/512/portal.png')
		},
		{
			title: "Tailored Integrations",
			description: "Our SharePass development team specializes in creating bespoke integrations tailored to your organization's specific requirements, facilitating a fluid workflow and refined processes. Should you need a distinct functionality, we're on hand to develop it, ensuring your SharePass setup is truly one-of-a-kind.",
			img: require('../../assets/images/enterprise/512/integrations.png'),
			note: "** Please note, custom integrations are not included in the subscription cost of SharePass ONE"
		},
		{
			title: "Dedicated AWS Instance",
			description: "Rest assured that a dedicated AWS instance meets the highest security, compliance, and data protection standards. Your organisation’s sensitive information remains fortified within a controlled environment based on your region of choice.",
			img: require('../../assets/images/enterprise/512/dedicated-instance.png')
		},
		{
			title: "Envelope mode",
			description: "Exclusive to SharePass ONE, the \"Envelope Mode\" is a pioneering feature designed to solicit and safely collect data from external entities. This mode simplifies the secure transfer of confidential information by allowing third parties to deposit data into a protected digital \"envelope\", ensuring the sender and receiver maintain optimum security and compliance during the exchange.",
			img: require('../../assets/images/enterprise/512/envelopes-receive-data.png')
		},
		/*{
			title: "",
			description: "",
			img: null
		},*/
		{
			title: "AI-Powered Inspector Module (In Beta)",
			description: "Harness the capabilities of ML (machine learning) and AI (artificial intelligence) to scrutinize documents and images, identifying potentially sensitive content that merits encryption, thereby amplifying your data security measures.",
			img: require('../../assets/images/enterprise/512/inspector-ai.png')
		}
	];

	useEffect(() => {
		setTimeout(() => window.scrollTo(0, 0), 500);

		/*enterpriseDataApi();*/

		setData({
			_id: "644f5c7c3681f42ccab9355e",
			createdAt: "2023-05-01T06:30:20.340Z",
			updatedAt: "2023-07-10T13:24:19.203Z",
			__v: 0,
			title: "SharePass One",
			desc: "",
			sec_desc:
				"Enhance your organization's security, control, and scalability with our customizable solutions, featuring white labelling, custom domain, SSO, and private instances in your preferred location for elevated standards.",
			sec_title: "Enterprise Solutions",
			sec_image: "public/files/2023-07-06T14-21-20.387Z-2.webm",
			sec_7_desc:
				"Silent Breach is a cybersecurity company that provides multiple services, such as vulnerability assessment, cloud security, and ransomware prevention.\r\n\r\nSharePass helps them to distribute highly classified vulnerability reports to their customers with ease and security.",
			sec_7_image: "public/files/2023-06-03T09-43-56.938Z-silent-breach (1).png",
			sec_7_title: "Silent Breach",
			sec_8_desc:
				"Schur is a company with over 175 years of history in the packaging business, providing the market with products from plastic bags to industrial packaging machinery.\r\n\r\n Using SharePass, Schur can maintain the highest standards of security while working collaboratively internally and externally.",
			sec_8_image: "public/files/2023-06-03T09-45-29.046Z-schur (1).png",
			sec_8_title: " Schur",
			sec_9_desc:
				" Natan is a technology company with specific expertise and a focus on telecommunications.\r\n\r\nWith SharePass, Natan can confidently deliver critical login information to their customers, allowing them to quickly and securely connect to their services.",
			sec_9_image: "public/files/2023-06-03T09-45-29.048Z-nattan.png",
			sec_9_title: "Natan",
		});
	}, []);

	return (
		<Fragment>
			<Helmet>
				<title>Enterprise | SharePass</title>
				<meta property="description" content={defaultMetaDescription} />
				<meta property="og:title" content="Enterprise | SharePass" />
				<meta property="og:description" content={defaultMetaDescription} />
				<meta property="og:image" content={defaultOgImg} />
			</Helmet>
			<section className="section-container">
				<div className="content-wrapper content-wrapper-center-end">
					<div className="content-wrap pad-left first-block-enterprise">
						<TinyTitle title="Enterprise Security" />
						<h1>SharePass One</h1>
						<p className="desktop-text">SharePass One is our premier solution crafted for Enterprise clientele and aligns with large organisations' current robust security and collaboration needs.</p>
						<p className="mobile-text">SharePass One is our premier solution crafted for Enterprise clientele and aligns with large organisations' current robust security and collaboration needs.</p>
						<button type='button' className="second" onClick={contactSales} >
							Contact Sales
						</button>
					</div>
					<video className="desktop-video" src={enterpriseVideo} autoPlay loop playsInline muted></video>
					<video className="mobile-video" src={enterpriseVideoVer} autoPlay loop playsInline muted></video>
				</div>
			</section>
			{false && <section className="features_sec" id="features">
				<div className="content_sec">
					<div className="flap" style={{opacity: 1, transition: "all 1.5s ease-in-out"}}>
						<div className='text_wrap pr first-block-enterprise'>
							<div className="text_wraper">
								<TinyTitle title="Empowering Enterprise Security with" />
								<h1>SharePass One</h1>
							</div>
							<p>Welcome to SharePass ONE, our flagship product developed for Enterprise clients. “SharePass One” is a comprehensive suite of advanced features tailored to meet modern organisations’ stringent security and collaboration demands. This enterprise-grade version of SharePass elevates data protection, data sovereignty, sharing efficiency, and administrative control, ensuring that sensitive information remains safeguarded while enabling seamless collaboration across platforms.</p>
							<button type='button' className="second" onClick={contactSales} >
								Contact Sales
							</button>
						</div>
						<div className="video_wrap">
							<video
								className="img_video"
								src={enterpriseVideo}
								autoPlay
								loop
								muted
								playsInline
							/>
							{/* <Player src={enterpriseVideo} autoplay loop /> */}
						</div>
					</div>
				</div>
			</section>}
			<section className="enterprise_landing">
				<div className="content_sec">
					<div className="text_wraper">
						<h2>Exclusive Features</h2>
					</div>
					<div className="text-intro">
						<p className="para">
						“SharePass One” stands out with its exclusive advanced features tailored for discerning enterprises. Beyond the foundational encryption and security standards, it boasts dedicated geo instances ensuring data sovereignty, Single Sign-On (SSO) capabilities for streamlined access, and extensive white-labelling options for a personalized user experience. The platform also offers fine-grained access controls, allowing administrators to specify user roles, data access levels, and detailed audit trails for real-time monitoring and accountability. In essence, SharePass One redefines how enterprises approach, manage, and ensure the security and efficiency of their data-sharing processes. Below, you can explore those features in more detail.
						</p>
					</div>
				</div>
			</section>

			<section className="features_sec" id="features">
				<div className="content_sec">
					<EnterpriseFeature elements={featuredItems} />
				</div>
			</section>
			
			{false && <section className="enterprise_landing">
				<div className="content_sec">
					<div className="text_wraper">
						<h2>Summary</h2>
					</div>
					<div className="text-intro">
						<p className="para">
						SharePass ONE for Enterprise extends beyond data protection, offering an unparalleled suite of features to enhance collaboration, streamline workflows, and empower administrators. With advanced security measures, seamless integration options, customisation capabilities, and a commitment to innovation, SharePass ONE ensures that your organisation’s sensitive information remains under your control, even in today’s dynamic and collaborative digital landscape.
						</p>
					</div>
				</div>
			</section>}
			<UseCases
				image1={data.sec_7_image}
				image2={data.sec_8_image}
				image3={data.sec_9_image}
				title1={data.sec_7_title}
				title2={data.sec_8_title}
				title3={data.sec_9_title}
				para1={data.sec_7_desc}
				para2={data.sec_8_desc}
				para3={data.sec_9_desc}
			/>
		</Fragment>
	);
};

export default Enterprise;
