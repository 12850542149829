import "./effortlesssharing.css";
import {useEffect, useState} from "react";

// mp4s
import video from "../../../assets/animations/Effortless-file-sharing.mp4";


const EffortlessSharing = (props) => {
	/*const [active, setActive] = useState('');
    function fillProgressBarA() {
        setTimeout(() => { fillProgressBarB() }, 7000);
        setActive('A');
    }
    function fillProgressBarB() {
        setTimeout(() => { fillProgressBarA() }, 7000);
        setActive('B');
    }
    useEffect(() => {
        fillProgressBarA();
    }, []);*/

	return (
		<section className="effortless">
			<div className="content_sec">
				<div className="grid_sec">
					{/* For Screen > 900px */}
					<div className="text_wraper">
						<h2>Simplified Secure File Transfers</h2>
						<div className={`text_block open`}>
							<h3>Effortless Transmission:</h3>
							<p>Send files with confidence. Our platform emphasizes privacy and ease, ensuring each transfer remains confidential.</p>
						</div>
						<div className={`text_block open`}>
							{false && (
								<div className="progress">
									<div className={`filler`}></div>
								</div>
							)}
							<h3>Universal Secure Reception:</h3>
							<p>Gather files safely, even from non-SharePass users. Provide the link and modernize your file collection approach instantly.</p>
						</div>
					</div>
					{/* For Screen < 900px */}
					<div className="small_text_wrap">
						<h2>Simplified Secure File Transfers</h2>
						<div className="horizontal_wrap">
						</div>
						<div className="h_text_block">
							<h3>Effortless Transmission:</h3>
							<p>Send files with confidence. Our platform emphasizes privacy and ease, ensuring each transfer remains confidential.</p>
						</div>
						<div className="h_text_block">
							<h3>Universal Secure Reception:</h3>
							<p>Gather files safely, even from non-SharePass users. Provide the link and modernize your file collection approach instantly.</p>
						</div>
					</div>
					<div className="video_wraper">
						{/* <Player src={video} loop autoplay /> */}
						<video
							className="anim_video"
							src={video}
							autoPlay
							loop
							muted
							playsInline
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default EffortlessSharing;
