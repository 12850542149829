import { useNavigate } from "react-router-dom";
import "./plans.css";
import { useState, useEffect } from "react";
import frontendConfigApp from "../../../assets/js/config";

const Plans = () => {
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();
    const statuchChanger = () => {
        setStatus(!status);
    }
    return (
        <section className="plans">
            <div className="content_sec">
                {false && <div className="switch_line">
                    <p className={!status ? 'active' : ''}>Billed monthly</p>
                    <button type="button" className={status ? 'active' : ''} onClick={statuchChanger}>
                        <span style={{ backgroundColor: status ? '#fff' : '#bbb' }}></span>
                    </button>
                    <p className={status ? 'active' : ''}>Billed yearly <br />
                        (Save up to 10%)</p>
                </div>}
                <div className="all_plans">
                    <div className="plan">
                        <div className="text">
                            <h6>Consumer</h6>
                            <h3 className="capit">Free</h3>
                            <div className="mid_text">
                                <p className="center">Start with SharePass without any investment. Our Consumer Plan offers essential features with moderate usage allowances, making it an ideal choice for individuals keen on initiating their privacy journey.</p>
                                
                            </div>
                            <p className="mid">'Consumer' plan includes:</p>
                            <p className="features">Easily send text-only secrets</p>
                            <p className="features">Send up to 20 secrets daily</p>
                            <p className="features">Use pre-defined templates for quick secret creation</p>
                            <p className="features">Organize and categorize secrets with labels</p>
                            <p className="features">Integrate with your applications using API and webhooks</p>
                            <p className="features">Manage secrets on the go with our mobile app and browser extension Personal</p>
                        </div>
                        <strong style={{margin:"20px 0 0 0"}}><small>** Please note that this plan is strictly for non-commercial use and should be linked with a personal email, not a custom domain.</small></strong>
                        <button onClick={() => {
                            window.location.href = `${frontendConfigApp.app_url}auth/register`;
                        }} type="button" className="planbtn">Sign up</button>
                    </div>

                    {false &&<div className="plan">
                        <div className="text">
                            <h6>Personal</h6>
                            <h3>{status ? "$42.99/year" : "$4/mo"}</h3>
                            <p className="tag">billed {status ? "yearly" : "monthly"}</p>
                            <div className="mid_text">
                                <p className="center">Take control of your personal information, offering enhanced privacy and expanded storage options.</p>
                            </div>
                            <p className="mid">Everything in 'Free' plus:</p>
                            <p className="features">Safely receive secrets from others</p>
                            <p className="features">No limit on the number of secrets you can send or receive</p>
                            <p className="features">Enjoy increased storage capacity of 100 MB</p>
                            <p className="features">Attach and share files securely</p>
                            <p className="features">Monitor and review access logs to track your secrets</p>

                        </div>
                        <button type="button" className="planbtn">Learn More</button>
                    </div>}

                    <div className="plan">
                        <div className="text">
                            <h6>MSP</h6>
                            <h3 className="capit">Coming Soon</h3>
                            {false && <p className="tag">billed {status ? "yearly" : "monthly"}</p>}
                            <div className="mid_text">
                                <p className="center">Empower your small or medium-sized business, featuring secure collaboration tools and centralized data management.</p>
                            </div>
                            <p className="mid">Everything in 'Consumer' plus:</p>
                            <p className="features">Each user gets an increased storage capacity of 250 MB</p>
                            <p className="features">Create custom secrets tailored to your specific needs</p>
                            <p className="features">Secure your secrets using security keys</p>
                            <p className="features">Share resources across your organization</p>
                            <p className="features">Manage users and their access within your organization</p>
                            <p className="features">Implement and enforce security policies Enterprise</p>
                        </div>
                        <button onClick={() => navigate('/register-your-interest')} type="button" className="planbtn">Register your interest</button>
                    </div>

                    <div className="plan">
                        <div className="text">
                            <h6>Enterprise</h6>
                            <h3 onClick={() => navigate('/contactsales')} className="capit clickable">Contact us</h3>
                            <div className="mid_text">
                                <p className="center">Provides advanced security measures and dedicated support for larger organisations with complex privacy needs.</p>
                            </div>
                            <p className="mid">Everything in 'MSP' plus:</p>
                            <p className="features">Unlimited storage capacity for your secrets and files</p>
                            <p className="features">Custom integrations to meet your specific business needs</p>
                            <p className="features">Customize the SharePass platform with your branding</p>
                            <p className="features">Integrate with your existing Single Sign-On solution for secure user authentication</p>
                            <p className="features">Set up a dedicated instance in your preferred location exclusively for your organization</p>
                        </div>
                        <button onClick={() => navigate('/enterprise')} type="button" className="planbtn">Learn More</button>
                    </div>

                </div>
                
            </div>
        </section>
    )
}

export default Plans;