import "./findplan.css";
import TinyTitle from "../../../components/tinytitle/TinyTitle";
import { useNavigate } from "react-router-dom";
import LearnMore from "../../../components/learnmore/LearnMore";

const FindPlan = (props) => {

    const navigate = useNavigate()

    const goToPlan = () => {
        navigate("/plans");
    }

    return(
        <section className="find_plan">
            <div className="content_sec">
                <div className="grids_sec">
                    <div className="left">
                        <div className="wrap">
                        <TinyTitle title="home" textcolor='#fff' />
                        <h2>Our Plans</h2>
                        <p className="text">SharePass currently features two plans: a free consumer version for non-commercial use and another tailored for enterprises and businesses. Stay tuned as we explore the potential of introducing more plans in the future.</p>
                        
                        <button className="clickable learn-more-desktop" onClick={goToPlan}>Learn More</button>
                        </div>
                    </div>
                    <div className="right">
                        

                        <div className="block">
                            <img src={`/assets/img/personal.svg`} alt='icon'/>
                            <h3>Consumer (free)</h3>
                            <p>This plan is ideal for individuals seeking complete control of their personal data. Offering core SharePass features that enhance your online security and minimizes your digital footprint.</p>
                            <br/>
                            <small>** Please note that this plan is strictly for non-commercial use and should be linked with a personal email, not a custom domain.</small>
                        </div>

                        <div className="block">
                            <img src={`/assets/img/share.svg`} alt='icon'/>
                            <h3>MSP Plan (coming soon)</h3>
                            <p>Tailored for managed service providers, SharePass streamlines secure communication between you and your clients, regardless of their tech proficiency. Ensure confidential information exchanges are fortified and effortless.</p>
                        </div>

                        <div className="block">
                            <img src={`/assets/img/enterprise.svg`} alt='icon'/>
                            <h3>Enterprise (SharePass One)</h3>
                            <p>Our Enterprise plan, “SharePass One”, is tailored for organizations seeking superior privacy management tools. Gain full command over sensitive data with exclusive offerings such as dedicated geo instances, SSO, and white labelling. Adapted to meet your specific requirements, this plan delivers paramount privacy and security for your enterprise.</p>
                        </div>

                    </div>

                    <button className="clickable learn-more-mobile" onClick={goToPlan}>Learn More</button>
                </div>
            </div>
        </section>
    )
}

export default FindPlan;