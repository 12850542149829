import { estimateReadingTime, decodeHtml, formatDate, cleanTags } from "../../../services/utils";
import {defaultMetaDescription, defaultMetaTitle,defaultOgImg} from "../../../assets/js/blogConfig";
import Helmet from 'react-helmet';
import { useParams, useNavigate, useLocation } from "react-router-dom";

const KnowledgeBaseContent = (dataContent) => {

    const navigate = useNavigate();
    
    const metaDescription = dataContent.content && dataContent.content.rendered ? cleanTags(dataContent.content.rendered) : defaultMetaDescription;
    
    let categoriesIndex = Object.keys(dataContent.categories);

    if(dataContent.isCategory){
        for(let i = 0, size = categoriesIndex.length; i < size; i++){
            if(dataContent.categories[categoriesIndex[i]].parent != dataContent.id){
                delete categoriesIndex[i];
            }
        }
    }
    
    return (dataContent.isCategory ?
        <div className="single_blog">
            <Helmet>
              <title>{dataContent.name ? `${decodeHtml(dataContent.name)} | SharPass` : defaultMetaTitle}</title>
              <meta property="description" content={defaultMetaDescription} />
              <meta property="og:title" content={dataContent.name ? `${decodeHtml(dataContent.name)} | SharPass` : defaultMetaTitle} />
              <meta property="og:description" content={defaultMetaDescription} />
              <meta property="og:image" content={defaultOgImg} />
            </Helmet>
            <div className="date_line">
                <div className="tag">Knowledge Base</div>
            </div>
            <div className="kb-main-category">
                <h1 className="blogtitle">
                    {dataContent.name}
                </h1>
                {dataContent.articles && dataContent.articles.length && <p className="kb-subtitle">{dataContent.articles.length} {dataContent.articles.length == 1? 'article' : 'articles'} <b>in this category</b></p>}
            </div>

            {dataContent.articles && dataContent.articles.length > 0 && <article className="kb-artciles-list"> 
                { dataContent.articles.map((article, i) => {  
                    let cleanDescription = cleanTags(article.content.rendered);
                    let description = cleanDescription.length > 200 ? cleanDescription.substring(0,197) + "..."  : cleanDescription;
                    return <div onClick={() => navigate(`/knowledgebase/${article.slug}`, { state: { type: 'article'} })} className="kb-blog-listing">
                        <h3>{article.title && article.title.rendered ? decodeHtml(article.title.rendered) : ''}</h3>
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                        <span>Updated on: {formatDate(article.date)}</span>
                    </div>;
                })}
            </article>}

            {categoriesIndex.size > 0 && <article className="kb-artciles-list"> 
                
                { categoriesIndex.map((index) => {  
                    let category = dataContent.categories[index];
                    return <div onClick={() => navigate(`/knowledgebase/${category.slug}`, { state: { type: 'category'} })} className="kb-blog-category">
                        <h3>{category.name}</h3>
                        {category.articles && category.articles.length && <p className="kb-subtitle">{category.articles.length} {category.articles.length == 1? 'article' : 'articles'} <b>in this category</b></p>}
                    </div>;
                })}
            </article>}
        </div>
        :
        <div className="single_blog">
            <Helmet>
              <title>{dataContent.title && dataContent.title.rendered ? `${decodeHtml(dataContent.title.rendered)} | SharPass` : defaultMetaTitle}</title>
              <meta property="description" content={metaDescription.length > 200 ? metaDescription.substring(0,197) + "..."  : metaDescription} />
              <meta property="og:title" content={dataContent.title && dataContent.title.rendered ? `${decodeHtml(dataContent.title.rendered)} | SharPass` : defaultMetaTitle} />
              <meta property="og:description" content={metaDescription.length > 200 ? metaDescription.substring(0,197) + "..."  : metaDescription} />
              <meta property="og:image" content={dataContent.fimg_url? dataContent.fimg_url : defaultOgImg} />
            </Helmet>
            <div className="date_line">
                <div className="tag">Knowledge Base</div>
                <p className="date">  {dataContent.event_reference_date ? dataContent.event_reference_date : dataContent.date ? formatDate(dataContent.date) : ''}</p>
                
            </div>
            <h1 className="blogtitle">
            {dataContent.title && dataContent.title.rendered ? decodeHtml(dataContent.title.rendered) : ''}
            </h1>
            <div className="timing">
                <svg
                    width={24}
                    height={24}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.75 3.797C6.031 1.516 8.781.375 12 .375c3.219 0 5.953 1.14 8.203 3.422 2.281 2.25 3.422 4.984 3.422 8.203 0 3.219-1.14 5.969-3.422 8.25-2.25 2.25-4.984 3.375-8.203 3.375-3.219 0-5.969-1.125-8.25-3.375C1.5 17.969.375 15.219.375 12c0-3.219 1.125-5.953 3.375-8.203ZM22.125 12c0-2.813-1-5.203-3-7.172C17.156 2.86 14.781 1.875 12 1.875c-2.813 0-5.203 1-7.172 3C2.86 6.844 1.875 9.219 1.875 12c0 2.813.984 5.203 2.953 7.172 2 1.969 4.39 2.953 7.172 2.953 2.813 0 5.203-.984 7.172-2.953 1.969-2 2.953-4.39 2.953-7.172Zm-6.984 4.125-3.797-2.766a.46.46 0 0 1-.235-.421v-7.5c0-.375.188-.563.563-.563h.656c.375 0 .563.188.563.563v6.843l3.328 2.438c.281.219.312.469.093.75l-.375.562c-.218.282-.484.313-.796.094Z"
                        fill="#02F3A2"
                    />
                </svg>
                <p>{`~${dataContent.content && dataContent.content.rendered? estimateReadingTime(dataContent.content.rendered) : 1} Min read`}</p>
            </div>
            <article>              
                <div className="blog_injector">
                
                <p>                  
                    <div dangerouslySetInnerHTML={{ __html: dataContent.content && dataContent.content.rendered ? dataContent.content.rendered : '' }} className="container_wrap" />
                </p> 

                </div>
            </article>
        </div>
    );
}

export default KnowledgeBaseContent;